<template>
    <b-row class="related mt-40">
        <b-col cols="12">
            <h5>{{ $t('page.product.relatedHeading') }}</h5>
            <product-listing-slider :products="related" />
        </b-col>
    </b-row>
</template>

<script>
    import { mapState } from 'vuex';

    import ProductListingSlider from '@/components/listing/ProductListingSlider';
    export default {
        components: { ProductListingSlider },
        name: 'RelatedListing',
        computed: {
            ...mapState('product', ['related']),
        },
    };
</script>
